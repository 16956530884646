import { z } from 'zod';

const zodErrorMap = (issue: z.ZodIssueOptionalMessage, ctx: z.ErrorMapCtx) => {
  if (issue.code === z.ZodIssueCode.invalid_type) {
    if (ctx.defaultError === 'Required') {
      return { message: { key: 'validation:required' } };
    }
  }

  if (issue.code === z.ZodIssueCode.invalid_string) {
    if (ctx.defaultError === 'Invalid email') {
      return { message: { key: 'validation:email' } };
    }
  }

  if (issue.code === z.ZodIssueCode.too_small) {
    return { message: { key: 'validation:stringMin', context: { min: issue.minimum } } };
  }

  if (issue.code === z.ZodIssueCode.too_big) {
    return { message: { key: 'validation:stringMax', context: { max: issue.maximum } } };
  }

  return { message: ctx.defaultError };
};

export default zodErrorMap;
