import { TOKEN_COOKIE } from 'config/environment';
import { useSessionStorageState } from 'hooks/common/useStorageState';
import useMutationService from 'hooks/services/useMutationService';
import usePrefetchService from 'hooks/services/usePrefetchService';
import Cookies from 'js-cookie';
import { isNil } from 'lodash-es';
import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo } from 'react';
import accountService from 'services/account/accountService';
import authService, { AUTH_CACHE_KEY, SigninParams } from 'services/authentication/authService';
import { AuthUser } from 'types/authentication/authentication';

type AuthContextContent = {
  reset: () => void;
  signin: {
    signin: (params: SigninParams) => void;
    isSigninError: boolean;
    isSigninLoading: boolean;
    isSigninSucces: boolean;
    signinError: unknown;
    authUser: AuthUser | undefined;
  };
  logout: () => void;
  token: string | undefined;
};
const AuthContext = createContext<AuthContextContent | undefined>(undefined);

const defaultCache = () => {
  const token = Cookies.get(TOKEN_COOKIE);

  if (isNil(token)) {
    return undefined;
  }

  return { token, rememberMe: true };
};

type AuthContextProviderProps = { children: ReactNode };

const AuthProvider = ({ children }: AuthContextProviderProps) => {
  const [cached, setCached] = useSessionStorageState<Pick<AuthUser, 'token'> | undefined>(AUTH_CACHE_KEY, defaultCache());

  const {
    mutate: signin,
    isError: isSigninError,
    isLoading: isSigninLoading,
    isSuccess: isSigninSucces,
    error: signinError,
    data: authUser,
    reset: resetSignin,
  } = useMutationService(authService.signin);

  const token = useMemo(() => cached?.token ?? authUser?.token, [authUser?.token, cached?.token]);
  const prefetchService = usePrefetchService(token);

  const reset = useCallback(() => {
    // reset cached
    resetSignin();
    setCached(undefined);
  }, [resetSignin, setCached]);

  // when logout succeed, reset
  const { mutate: mutateLogout } = useMutationService(authService.logout, { onSuccess: reset });

  const logout = useCallback(() => mutateLogout(), [mutateLogout]);

  useEffect(() => {
    document.addEventListener('logout', logout);

    return () => {
      document.removeEventListener('logout', logout);
    };
  });

  useEffect(() => {
    if (!isNil(token)) {
      prefetchService(accountService.getAccountDetails);
    }
  }, [prefetchService, token]);

  const context = useMemo(
    () => ({
      reset,
      signin: { signin, isSigninError, isSigninLoading, isSigninSucces, signinError, authUser },
      logout,
      token,
    }),
    [reset, signin, isSigninError, isSigninLoading, isSigninSucces, signinError, authUser, logout, token],
  );

  return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>;
};

export default AuthProvider;

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return context;
};
