import './index.scss';

import { QueryClient } from '@tanstack/react-query';
import AppProvider from 'components/AppProvider';
import Loading from 'components/design/loading/Loading';
import zodErrorMap from 'config/form/zodErrorMap';
import ROUTES from 'config/routes';
import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter } from 'react-router-dom';
import SERVICE_CONFIG from 'services/serviceConfig';
import { z } from 'zod';

z.setErrorMap(zodErrorMap as z.ZodErrorMap);

const App = lazy(() => import('App'));

const queryClient = new QueryClient(SERVICE_CONFIG);

const router = createBrowserRouter(ROUTES(queryClient));

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <AppProvider queryClient={queryClient}>
      <Suspense fallback={<Loading className="loading-center" />}>
        <App router={router} />
      </Suspense>
    </AppProvider>
  </React.StrictMode>,
);
