import { isNil } from 'lodash-es';

export type BrowserStorage = 'local' | 'session';

const getStorage = (storage: BrowserStorage) => {
  if (typeof window !== 'undefined') {
    // Client-side-only code
    switch (storage) {
      case 'local':
        return window.localStorage;
      case 'session':
        return window.sessionStorage;
      default:
        throw new Error('Wrong storage');
    }
  }

  return undefined;
};

/**
 * Get a value by key from a browser storage
 * @param storage
 * @param key
 * @returns
 */
export const getValueFromStorage = <Data>(storage: BrowserStorage, key: string) => {
  const store = getStorage(storage);

  if (!isNil(store)) {
    try {
      const stored = store.getItem(key);

      if (!isNil(stored)) {
        return JSON.parse(stored) as Data;
      }
    } catch (_) {
      // not accessible
      return undefined;
    }
  }

  return undefined;
};

/**
 * Associate a value to a key in a browser storage
 * @param storage
 * @param key
 * @param value
 */
export const setValueFromStorage = <Data>(storage: BrowserStorage, key: string, value: Data) => {
  const store = getStorage(storage);

  if (!isNil(store)) {
    try {
      store.setItem(key, JSON.stringify(value));
    } catch (_) {
      // not accessible
    }
  }
};

/**
 * Remove by key in a browser storage
 * @param storage
 * @param key
 * @param value
 */
export const removeFromStorage = (storage: BrowserStorage, key: string) => {
  const store = getStorage(storage);

  if (!isNil(store)) {
    try {
      store.removeItem(key);
    } catch (_) {
      // not accessible
    }
  }
};
