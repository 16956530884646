import { createTheme } from '@mui/material';

const APP_THEME = createTheme({
  palette: {
    primary: {
      main: '#232223',
    },
    error: {
      main: '#d2232a',
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          fontWeight: 'inherit',
          fontFamily: 'inherit',
          lineHeight: 'inherit',
          letterSpacing: 'inherit',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          fontSize: 'inherit',
          fontWeight: 'inherit',
          fontFamily: 'inherit',
          lineHeight: 'inherit',
          letterSpacing: 'inherit',
          padding: 0,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          fontSize: 'inherit',
          fontWeight: 'inherit',
          fontFamily: 'inherit',
          lineHeight: 'inherit',
          letterSpacing: 'inherit',
          margin: '0',
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          '::after': {
            borderColor: '#232223',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 'inherit',
          fontWeight: 'inherit',
          fontFamily: 'inherit',
          lineHeight: 'inherit',
          letterSpacing: 'inherit',
          '&.Mui-focused': {
            color: '#232223',
          },
        },
      },
    },
  },
});

export default APP_THEME;
