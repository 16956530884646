import './Loading.scss';

import { CircularProgress } from '@mui/material';
import clsx from 'clsx';

type LoadingProps = { className?: string; size?: number };

const Loading = ({ className, size }: LoadingProps) => (
  <main className={clsx(className)}>
    <CircularProgress size={size} />
  </main>
);

export default Loading;
