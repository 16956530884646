import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import buildServiceFetcher from 'services/buildServiceFetcher';
import buildServiceKey from 'services/buildServiceKey';
import { OneService } from 'types/services/services';

const usePrefetchService = <Data, Params = undefined>(token?: string) => {
  const queryClient = useQueryClient();

  const prefetchService = useCallback(
    (service: OneService<Data, Params>, ...params: OptionalArg<Params>) => {
      const queryKey = buildServiceKey(service, params);
      const fetcher = buildServiceFetcher(service, token);
      queryClient.prefetchQuery(queryKey, fetcher);
    },
    [queryClient, token],
  );

  return prefetchService;
};

export default usePrefetchService;
