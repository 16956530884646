import NotificationProvider from 'components/design/notification/NotificationProvider';
import NOTIFICATIONS from 'config/notification/notification';
import { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type AppNotificationProviderProps = { children: ReactNode };

const AppNotificationProvider = ({ children }: AppNotificationProviderProps) => {
  const { t } = useTranslation();

  const notifications = useMemo(
    () =>
      NOTIFICATIONS.map(({ title, content, actions, ...rest }) => ({
        ...rest,
        title: title ? t(title) : undefined,
        content: t(content),
        actions: actions?.map(({ content: aContent, ...aRest }) => ({ ...aRest, content: t(aContent) })),
      })),
    [t],
  );

  return (
    <NotificationProvider closeText={t('notification:closeText')} notifications={notifications}>
      {children}
    </NotificationProvider>
  );
};

export default AppNotificationProvider;
