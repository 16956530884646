import './ErrorPage.scss';

import Button from 'components/design/button/Button';
import ContactSupport from 'components/layout/error-page/message/ContactSupport.message';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

type ButtonProps = { name: string; action: () => void };

type ErrorPageProps = { title: string; description: string; button?: ButtonProps; img: ReactNode };

const ErrorPage = ({ title, description, button, img }: ErrorPageProps) => {
  const { t } = useTranslation();

  return (
    <section className="error-page inline-form-item align-center top col col-12">
      <div className="error-container">
        <div className="left">
          <h1>{title}</h1>
          <h1>{description}</h1>
          {button && (
            <Button default large className="m-t-30" onClick={button.action}>
              {button.name}
            </Button>
          )}
          <p className="m-t-80">
            <span className="label black">{t('error:needAssistance')}</span>
            <br />
            <ContactSupport />
          </p>
        </div>
        <div className="right">{img}</div>
      </div>
    </section>
  );
};

export default ErrorPage;
