import { MutationCache, QueryCache } from '@tanstack/react-query';
import onApiError from 'utils/api/onApiError';

const SERVICE_CONFIG = {
  queryCache: new QueryCache({
    onError: onApiError,
  }),
  mutationCache: new MutationCache({
    onError: onApiError,
  }),
};

export default SERVICE_CONFIG;
