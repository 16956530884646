import { SvgIcon } from '@mui/material';
import ErrorPage from 'components/layout/error-page/ErrorPage';
import useNavNavigate from 'hooks/navigation/useNavNavigate';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Image400 } from 'resources/svg/errors/400.svg';

const Error400 = () => {
  const { t } = useTranslation();
  const navigate = useNavNavigate();

  return (
    <ErrorPage
      title={t('error:400.title')}
      description={t('error:400.description')}
      img={<SvgIcon component={Image400} inheritViewBox />}
      button={{
        name: t('common:goBack'),
        action: () => navigate(-1),
      }}
    />
  );
};

export default Error400;
