import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import Button from 'components/design/button/Button';
import NotificationAlertBase from 'components/design/notification/NotificationAlertBase/NotificationAlertBase';
import { forwardRef, useCallback, useMemo } from 'react';
import { Notification } from 'types/design/notification/notification';

type NotificationAlertProps = {
  /** Id of the notification */
  id: string | number;
  /** Variant of the notification */
  variant?: Notification['type'];
  /** Title of the notification */
  title?: string;
  /** Content of the notification */
  message: string;
  /** Actions of the notification */
  actions?: Notification['actions'];
  /** Callback in case of closing a notification */
  onCloseNotification: (id: string | number) => void;
  /** Close text */
  closeText: string;
};

/**
 * Display nicely a notification alert according to it's type.
 */
const NotificationAlert = forwardRef<HTMLDivElement, NotificationAlertProps>(
  ({ id, message, onCloseNotification, variant = 'info', title, actions, closeText }, ref) => {
    const onCloseAlert = useCallback(() => onCloseNotification(id), [id, onCloseNotification]);

    const alertAction = useMemo(
      () =>
        (
          actions?.map(({ content: contentAction, action }) => (
            <Button key={contentAction} small onClick={action}>
              {contentAction}
            </Button>
          )) ?? []
        ).concat(
          <IconButton key="closeButton" size="small" aria-label={closeText} title={closeText} color="inherit" onClick={onCloseAlert}>
            <CloseIcon fontSize="small" />
          </IconButton>,
        ),
      [actions, closeText, onCloseAlert],
    );

    return (
      <NotificationAlertBase ref={ref} onClose={onCloseAlert} type={variant} title={title} action={alertAction}>
        {message}
      </NotificationAlertBase>
    );
  },
);

export default NotificationAlert;
