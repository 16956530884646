/**
 * Identifies a browser error without disrupting the app's operations.
 * @see https://docs.newrelic.com/docs/browser/new-relic-browser/browser-agent-spa-api/noticeerror-browser-agent-api
 *
 * @param error
 * @param extra
 */
const manageError = (error: Error | string, extra?: Record<string, string | number>) => {
  if (process.env.REACT_APP_NEW_RELIC === 'true' && typeof window.newrelic !== 'undefined') {
    newrelic.noticeError(error, extra);
  } else {
    console.error('Manage error:', error, extra);
  }
};

export default manageError;
