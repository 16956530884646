import { ENVIRONMENT } from 'config/environment';
import OneBadResponse from 'errors/common/OneBadResponse';
import OneUnauthorizedError from 'errors/common/OneUnauthorizedError';
import { isEmpty, isNil, toNumber } from 'lodash-es';
import { AuthUser, AuthUserSSO, NewUserSignup } from 'types/authentication/authentication';
import { OneMutationService, OneMutationServiceMutator } from 'types/services/services';
import { identityApi } from 'utils/api/api';
import isHTTPError from 'utils/api/isHTTPError';
import { removeFromStorage } from 'utils/browser/browserStorage';

import AuthEmailUnconfirmedError from './error/AuthEmailUnconfirmedError';
import AuthInvalidCredentialsError from './error/AuthInvalidCredentialsError';
import AuthMaximumAttemptsError from './error/AuthMaximumAttemptsError';
import AuthSSOEmailNotFound from './error/AuthSSOEmailNotFound';

const SERVICE_ID = 'auth';
const environmentSplited = ENVIRONMENT.substring(0, 3);

export const AUTH_CACHE_KEY = `${environmentSplited}AuthToken`;

export type SigninParams = { username: string; password: string; returnUrl: string };

export type SigninWithSSOParams = { emailAddress: string; returnUrl: string };

export type SignupParams = {
  emailAddress: string;
  password: string;
  firstName: string;
  lastName: string;
  returnUrl: string;
  validateEmailUrl: string;
  subscribeNewsletter?: boolean;
  agreeTermsAndConditions?: boolean;
};

type VerifyEmailParams = {
  userId: string;
  hash: string;
};

const signinMutation: OneMutationServiceMutator<AuthUser, SigninParams> = async (params: SigninParams) => {
  if (isNil(params?.username) || isNil(params?.password) || isNil(params?.returnUrl)) {
    throw new Error('Unexpected empty params');
  }

  const postParams = {
    username: params.username,
    password: params.password,
    returnUrl: params.returnUrl,
  };

  try {
    const { data } = await identityApi.post<AuthUser, typeof postParams>('account/login', {
      params: postParams,
    });

    if (!data?.status) {
      throw new Error('Invalid credentials');
    }

    return data;
  } catch (error) {
    if (error instanceof OneUnauthorizedError) {
      throw new AuthEmailUnconfirmedError(error.cause);
    }

    if (isHTTPError(error)) {
      const status = toNumber(error.response.status);

      switch (status) {
        case 401:
          throw new AuthInvalidCredentialsError(error);
        case 429:
          throw new AuthMaximumAttemptsError(error);
        default:
          throw error;
      }
    }

    throw error;
  }
};

const signinWithSSOMutation: OneMutationServiceMutator<AuthUserSSO, SigninWithSSOParams> = async (params: SigninWithSSOParams) => {
  if (isNil(params?.emailAddress) || isNil(params?.returnUrl)) {
    throw new Error('Unexpected empty params');
  }

  const postParams = {
    email: params.emailAddress,
    returnUrl: params.returnUrl,
  };

  try {
    const { data } = await identityApi.post<AuthUserSSO, typeof postParams>('sso/login', {
      params: postParams,
    });

    if (isNil(data) || isEmpty(data.identityProviders)) {
      throw new OneBadResponse(data);
    }

    return data;
  } catch (error) {
    if (isHTTPError(error)) {
      const status = toNumber(error.response.status);

      switch (status) {
        case 404:
          throw new AuthSSOEmailNotFound(error);
        default:
          throw error;
      }
    }

    throw error;
  }
};

const signupMutation: OneMutationServiceMutator<NewUserSignup, SignupParams> = async (params: SignupParams) => {
  if (
    isNil(params?.emailAddress) ||
    isNil(params?.password) ||
    isNil(params?.returnUrl) ||
    isNil(params?.firstName) ||
    isNil(params?.lastName) ||
    isNil(params?.returnUrl)
  ) {
    throw new Error('Unexpected empty params');
  }

  const postParams = {
    firstName: params.firstName,
    lastName: params.lastName,
    emailAddress: params.emailAddress,
    password: params.password,
    newsletter: params.subscribeNewsletter ? 1 : 0,
    validateEmailUrl: params.validateEmailUrl,
    legal: params.agreeTermsAndConditions,
    returnUrl: params.returnUrl,
  };

  const { data } = await identityApi.post<NewUserSignup, typeof postParams>('account/signup', {
    params: postParams,
  });

  return data;
};

const logoutMutation: OneMutationServiceMutator<void> = async () => {
  try {
    removeFromStorage('session', AUTH_CACHE_KEY);
    await identityApi.post('account/logout');
  } catch (_) {
    // ignore errors
  }
};

const verifyEmailMutation: OneMutationServiceMutator<void, VerifyEmailParams> = async (params: VerifyEmailParams) => {
  if (isNil(params?.userId) || isNil(params?.hash)) {
    throw new Error('Unexpected empty params');
  }

  const { data } = await identityApi.put<void, VerifyEmailParams>('account/email/confirm', { params });

  return data;
};

const signin: OneMutationService<AuthUser, SigninParams> = {
  id: 'signin',
  serviceId: SERVICE_ID,
  mutation: signinMutation,
};

const signinWithSSO: OneMutationService<AuthUserSSO, SigninWithSSOParams> = {
  id: 'signinWithSSO',
  serviceId: SERVICE_ID,
  mutation: signinWithSSOMutation,
};

const signup: OneMutationService<NewUserSignup, SignupParams> = {
  id: 'signup',
  serviceId: SERVICE_ID,
  mutation: signupMutation,
};

const logout: OneMutationService<void> = {
  id: 'logout',
  serviceId: SERVICE_ID,
  mutation: logoutMutation,
};

const verifyEmail: OneMutationService<void, VerifyEmailParams> = {
  id: 'verifyEmail',
  serviceId: SERVICE_ID,
  mutation: verifyEmailMutation,
};

const authService = { signin, signinWithSSO, signup, logout, verifyEmail };

export default authService;
