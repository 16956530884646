import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

const ContactSupport = () => (
  <Trans i18nKey="error:contactSupport">
    Please contact the i3D.net support team by sending an e-mail to <a href="mailto:support@i3d.net">support@i3d.net</a>, call{' '}
    <a href="tel:+18004826910">+1 800 482 6910</a>
    &nbsp;or&nbsp;
    <a href="tel:+31108900070">+31 10 890 00 70</a>, or create a <Link to="/Support/Tickets/Create">ticket</Link>.
  </Trans>
);

export default ContactSupport;
