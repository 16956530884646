/* eslint-disable import/extensions */
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';

import ns from './ns.json';
import supportedLngs from './supportedLngs.json';

i18n
  .use(
    resourcesToBackend((language, namespace, callback) => {
      import(`./${language}/${namespace}.json`)
        .then((resources) => {
          callback(null, resources);
        })
        .catch((error) => {
          callback(error, null);
        });
    }),
  )
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      caches: ['sessionStorage'],
    },
    fallbackLng: 'en',
    ns,
    defaultNS: 'common',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    supportedLngs,
  });
