import { MutationFunction, useMutation, UseMutationOptions } from '@tanstack/react-query';
import { useMemo } from 'react';
import { OneMutationService } from 'types/services/services';

export type MutationOptions<Data, Params> = Omit<UseMutationOptions<Data, unknown, Params>, 'mutationFn'>;

const buildMutation =
  <Data, Params = undefined>(service: OneMutationService<Data, Params>): MutationFunction<Data, Params> =>
  (params) =>
    service.mutation(params);

const buildOptions = <Data, Params>(
  service: OneMutationService<Data, Params>,
  opts?: MutationOptions<Data, Params>,
): MutationOptions<Data, Params> => {
  const { options } = service;
  const key = opts?.mutationKey ?? options?.mutationKey ?? `${service.serviceId}-${service.id}`;

  return { ...options, ...opts, mutationKey: [key] };
};

const useMutationService = <Data, Params = undefined>(service: OneMutationService<Data, Params>, opts?: MutationOptions<Data, Params>) => {
  const mutationFn = useMemo(() => buildMutation(service), [service]);
  const options = useMemo(() => buildOptions(service, opts), [service, opts]);
  const mutate = useMutation({ mutationFn, ...options });

  return mutate;
};

export default useMutationService;
