export const STANDARD_STALE_TIME = {
  short: 10000, // 10 sec
  long: 300000, // 5 minutes
  permanent: Infinity,
};

export const STANDARD_CACHE_TIME = {
  short: process.env.NODE_ENV === 'test' ? Infinity : 600000, // 10 minutes
  long: process.env.NODE_ENV === 'test' ? Infinity : 86400000, // 1 day
  permanent: Infinity,
};
