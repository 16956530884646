import { CustomEventMap } from 'types/global/custom-events';

const triggerEvent = <Data>(name: keyof CustomEventMap, data?: Data) => {
  if (typeof document !== 'undefined') {
    // client side
    const event = new CustomEvent(name, { detail: data });
    document.dispatchEvent(event);
  }
};

export default triggerEvent;
