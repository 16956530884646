export default class OneBadResponse extends Error {
  data: unknown;

  constructor(data: unknown) {
    super('External bad response');

    this.data = data;

    Object.setPrototypeOf(this, OneBadResponse.prototype);
  }

  getData() {
    return this.data;
  }
}
