import { first, isNil } from 'lodash-es';
import { AccountDetailsServiceData } from 'services/account/accountServiceType';
import convertAccountDetailsFromService from 'services/account/utils/convertAccountDetailsFromService';
import { AccountDetails } from 'types/account/account';
import { OneService, OneServiceFetcher } from 'types/services/services';
import { oneApi } from 'utils/api/api';
import { STANDARD_CACHE_TIME, STANDARD_STALE_TIME } from 'utils/query/standardOptions';

const SERVICE_ID = 'account';

const getAccountDetailsFetcher: OneServiceFetcher<AccountDetails> = async (token) => {
  const { data } = await oneApi.get<AccountDetailsServiceData[]>(`account/details`, { token });

  const detail = first(data);

  if (isNil(detail)) {
    throw new Error('Unexpected empty account detail');
  }

  return convertAccountDetailsFromService(detail);
};

const getAccountDetails: OneService<AccountDetails> = {
  id: 'getAccountDetails',
  serviceId: SERVICE_ID,
  fetcher: getAccountDetailsFetcher,
  options: {
    staleTime: STANDARD_STALE_TIME.long,
    cacheTime: STANDARD_CACHE_TIME.long,
  },
};

const accountService = {
  getAccountDetails,
};

export default accountService;
