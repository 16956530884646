import OneBadRequestError, { OneBadRequestFields } from 'errors/common/OneBadRequest';
import { camelCase, first, isNil, mapKeys, mapValues } from 'lodash-es';
import { isRestApiInvalidParamError, isRestApiOldBasicError, RestApiError } from 'types/services/rest-api';

const buildFields = (apiError: RestApiError): OneBadRequestFields | undefined => {
  if (isRestApiInvalidParamError(apiError)) {
    return apiError['invalid-params'].reduce((previous, invalidParam) => ({ ...previous, [invalidParam.name]: invalidParam.reason }), {});
  }

  if (isRestApiOldBasicError(apiError)) {
    return mapKeys(
      mapValues(apiError.errors, (value) => first(value) ?? ''),
      (_, key) => camelCase(key),
    );
  }

  return undefined;
};

const buildBadRequestError = (apiError: RestApiError): OneBadRequestError | undefined => {
  const fields = buildFields(apiError);

  if (!isNil(fields)) {
    return new OneBadRequestError(fields, apiError);
  }

  return undefined;
};

export default buildBadRequestError;
