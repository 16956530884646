/* eslint-disable react/jsx-props-no-spreading */
import './Button.scss';

import { Button as ButtonUnstyled, ButtonProps as ButtonUnstyledProps } from '@mui/base';
import { CircularProgress } from '@mui/material';
import clsx from 'clsx';
import { forwardRef } from 'react';

type ButtonProps = ButtonUnstyledProps & {
  small?: boolean;
  extraLarge?: boolean;
  large?: boolean;
  right?: boolean;
  left?: boolean;
  default?: boolean;
  isLoading?: boolean;
};

const Button = forwardRef(
  (
    { extraLarge, large, small, disabled, children, right, left, default: defaultProp, isLoading, className, ...buttonProps }: ButtonProps,
    ref: React.ForwardedRef<HTMLButtonElement>,
  ) => (
    <ButtonUnstyled
      {...buttonProps}
      ref={ref}
      disabled={disabled}
      className={clsx(
        'btn',
        {
          'btn-xl': extraLarge,
          'btn-l': large,
          'btn-s': small,
          'btn-disabled': disabled,
          'btn-right': right,
          'btn-left': left,
          'btn-default': defaultProp,
        },
        className,
      )}
    >
      {!isLoading && children}
      {isLoading && <CircularProgress size={24} color="inherit" />}
    </ButtonUnstyled>
  ),
);

export default Button;
