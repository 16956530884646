const PAGES = {
  authentication: {
    signin: '/signin',
    signinWithSSO: '/signin-sso',
    signup: '/signup',
    verifyEmail: '/validate-email',
    forgotPassword: '/forgot-password',
    passwordResetConfirm: '/Password-Reset-Confirm',
  },
  home: '/',
  error: {
    error400: '/400',
    error401: '/401',
    error403: '/403',
    error500: '/500',
    errorJS: '/error',
  },
};

export default PAGES;
