import OneBusinessError from 'errors/common/OneBusinessError';
import OneUnauthorizedError from 'errors/common/OneUnauthorizedError';
import { isError } from 'lodash-es';
import triggerEvent from 'utils/design/event/triggerEvent';
import manageError from 'utils/logging/manageError';

const onApiError = (error: unknown) => {
  if (error instanceof OneUnauthorizedError) {
    triggerEvent('logout');
  }

  if (!(error instanceof OneBusinessError)) {
    const toLog = isError(error) ? error : new Error('Unknown error', { cause: error });
    manageError(toLog);
  }
};

export default onApiError;
