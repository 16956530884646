import { Alert, AlertTitle } from '@mui/material';
import { forwardRef, ReactNode } from 'react';
import { Notification } from 'types/design/notification/notification';

type NotificationAlertBaseProps = {
  /** Type of the notification */
  type: Notification['type'];
  /** Content of the notification */
  children?: ReactNode;
  /** Title of the notification */
  title?: string;
  /** Callback when the user close the notification */
  onClose: () => void;
  /** Override of the icon */
  icon?: ReactNode;
  /** Extra action */
  action?: ReactNode;
};

const NotificationAlertBase = forwardRef<HTMLDivElement, NotificationAlertBaseProps>(({ onClose, type, children, icon, title, action }, ref) => (
  <Alert ref={ref} icon={icon} onClose={onClose} severity={type} action={action}>
    {title && <AlertTitle>{title}</AlertTitle>}
    {children}
  </Alert>
));

export default NotificationAlertBase;
