import NotificationAlert from 'components/design/notification/NotificationAlert/NotificationAlert';
import { isNil } from 'lodash-es';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { ReactNode, useCallback, useEffect } from 'react';
import { Notification, NotificationEventDetail } from 'types/design/notification/notification';
import manageError from 'utils/logging/manageError';

declare module 'notistack' {
  interface VariantOverrides {
    info: {
      title?: string;
      closeText: string;
      actions?: Notification['actions'];
      onCloseNotification: (key: string | number) => void;
    };
    success: {
      title?: string;
      closeText: string;
      actions?: Notification['actions'];
      onCloseNotification: (key: string | number) => void;
    };
    warning: {
      title?: string;
      closeText: string;
      actions?: Notification['actions'];
      onCloseNotification: (key: string | number) => void;
    };
    error: {
      title?: string;
      closeText: string;
      actions?: Notification['actions'];
      onCloseNotification: (key: string | number) => void;
    };
  }
}

type NotificationProviderProps = { children: ReactNode; notifications: Notification[]; closeText: string };

const Internal = ({ children, notifications, closeText }: NotificationProviderProps) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const closeNotification = useCallback((key: string | number) => closeSnackbar(key), [closeSnackbar]);

  const showNotification = useCallback(
    (ev: CustomEvent<NotificationEventDetail>) => {
      const notification = notifications.find(({ id }) => id === ev.detail.id);

      if (!isNil(notification)) {
        const { content } = notification;
        const { title } = notification;
        enqueueSnackbar(content, { variant: notification.type, title, closeText, onCloseNotification: closeNotification });
      } else {
        console.info(notifications);
        manageError(new Error(`Unkown notification ${ev.detail.id}`));
      }
    },
    [closeNotification, closeText, enqueueSnackbar, notifications],
  );

  useEffect(() => {
    document.addEventListener('notification', showNotification);

    return () => {
      document.removeEventListener('logout', showNotification);
    };
  }, [showNotification]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

const NotificationProvider = ({ children, notifications, closeText }: NotificationProviderProps) => (
  <SnackbarProvider
    autoHideDuration={3000}
    maxSnack={3}
    Components={{ error: NotificationAlert, info: NotificationAlert, success: NotificationAlert, warning: NotificationAlert }}
  >
    <Internal notifications={notifications} closeText={closeText}>
      {children}
    </Internal>
  </SnackbarProvider>
);

export default NotificationProvider;
