import { QueryClient } from '@tanstack/react-query';
import buildServiceFetcher from 'services/buildServiceFetcher';
import buildServiceKey from 'services/buildServiceKey';
import buildServiceOptions from 'services/buildServiceOptions';
import { OneService } from 'types/services/services';

const queryService = <Data, Params = undefined>(
  queryClient: QueryClient,
  service: OneService<Data, Params>,
  token: string | undefined,
  ...params: OptionalArg<Params>
) => {
  const queryKey = buildServiceKey(service, params);
  const queryFn = buildServiceFetcher(service, token);
  const options = buildServiceOptions(service.options, params);

  return queryClient.fetchQuery({ ...options, queryKey, queryFn });
};

export default queryService;
