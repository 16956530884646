import { get, has, isArray, isFinite, isObject, isString } from 'lodash-es';

interface ProblemDetails {
  /**
   * A URI reference [RFC3986] that identifies the problem type.
   * This specification encourages that, when dereferenced, it provide human-readable documentation for the problem type (e.g., using HTML [W3C.REC-html5-20141028]).
   * When this member is not present, its value is assumed to be "about:blank".
   */
  type: string;
  /**
   * The HTTP status code([RFC7231], Section 6) generated by the origin server for this occurrence of the problem.
   */
  status: number;
  /**
   * A short, human-readable summary of the problem type.
   * It SHOULD NOT change from occurrence to occurrence of the problem, except for purposes of localization(e.g., using proactive content negotiation; see[RFC7231], Section 3.4).
   */
  title: string;
  /**
   * A URI reference that identifies the specific occurrence of the problem.
   * It may or may not yield further information if dereferenced.
   */
  instance?: string;
}

type RestApiOldBasicError = ProblemDetails & {
  errors: Record<string, Array<string>>;
};

type RestApiBasicError = ProblemDetails & {
  errors: {
    /**
     * The code detonating the error.
     */
    code: string;
    /**
     * The description of the failure.
     */
    description: string;
  }[];
};

type RestApiInvalidParamError = ProblemDetails & {
  'invalid-params': {
    /**
     * The name of the item being validated.
     */
    name: string;
    /**
     * The reason for the failure.
     */
    reason: string;
  }[];
};

export type RestApiError = RestApiOldBasicError | RestApiBasicError | RestApiInvalidParamError;

export const isRestApiError = (value: unknown): value is RestApiError =>
  has(value, 'type') &&
  isString(get(value, 'type')) &&
  has(value, 'title') &&
  isString(get(value, 'title')) &&
  has(value, 'status') &&
  isFinite(get(value, 'status'));

export const isRestApiInvalidParamError = (value: unknown): value is RestApiInvalidParamError =>
  isRestApiError(value) && has(value, 'invalid-params') && isArray(get(value, 'invalid-params'));

export const isRestApiOldBasicError = (value: unknown): value is RestApiOldBasicError =>
  isRestApiError(value) && has(value, 'errors') && isObject(get(value, 'errors')) && !isArray(get(value, 'errors'));

export const isRestApiBasicError = (value: unknown): value is RestApiBasicError =>
  isRestApiError(value) && has(value, 'errors') && isArray(get(value, 'errors'));
