import { QueryClient } from '@tanstack/react-query';
import { TOKEN_COOKIE } from 'config/environment';
import PAGES from 'config/pages';
import Cookies from 'js-cookie';
import { isEmpty, isNil } from 'lodash-es';
import { redirect } from 'react-router-dom';
import accountService from 'services/account/accountService';
import { AUTH_CACHE_KEY } from 'services/authentication/authService';
import queryService from 'services/queryService';
import { AuthUser } from 'types/authentication/authentication';
import { getValueFromStorage } from 'utils/browser/browserStorage';

const findToken = () => {
  const cookieToken = Cookies.get(TOKEN_COOKIE);

  if (!isEmpty(cookieToken)) {
    return cookieToken;
  }

  const sessionToken = getValueFromStorage<AuthUser>('session', AUTH_CACHE_KEY);

  if (!isNil(sessionToken) && !isEmpty(sessionToken.token)) {
    return sessionToken.token;
  }

  return undefined;
};

const signinLoader = (queryClient: QueryClient) => async () => {
  const token = findToken();

  if (!isNil(token)) {
    try {
      // checking validity of the token
      const data = await queryService(queryClient, accountService.getAccountDetails, token);

      if (!isNil(data)) {
        // TODO change that
        return redirect(PAGES.authentication.signup);
      }
    } catch (error) {
      // nothing todo
    }
  }

  return redirect(PAGES.authentication.signin);
};

export default signinLoader;
