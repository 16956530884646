export type OneBadRequestFields<K extends string = string> = Record<K, string | number>;

export default class OneBadRequestError<K extends string = string> extends Error {
  fields: OneBadRequestFields<K>;

  constructor(fields: OneBadRequestFields<K>, cause?: unknown) {
    super('Bad request error', { cause });

    this.fields = fields;

    Object.setPrototypeOf(this, OneBadRequestError.prototype);
  }

  getFields() {
    return this.fields;
  }
}
