import { QueryFunction } from '@tanstack/react-query';
import { OneService } from 'types/services/services';

const buildServiceFetcher =
  <Data, Params>(service: OneService<Data, Params>, token: string | undefined): QueryFunction<Data, [string, OptionalArg<Params>]> =>
  ({ queryKey }) => {
    const [, params] = queryKey;

    return service.fetcher(token, ...params);
  };

export default buildServiceFetcher;
