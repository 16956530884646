/**
 * All global providers required by ONE app to work
 */
// import i18n
import 'i18n/config';

import { ThemeProvider } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import AppNotificationProvider from 'components/app/AppNotificationProvider';
import AuthProvider from 'components/auth/AuthProvider';
import APP_THEME from 'config/theme';
import { ReactNode } from 'react';

type AppProviderProps = { children: ReactNode; queryClient: QueryClient };

const AppProvider = ({ children, queryClient }: AppProviderProps) => (
  <ThemeProvider theme={APP_THEME}>
    <AppNotificationProvider>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>{children}</AuthProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </AppNotificationProvider>
  </ThemeProvider>
);

export default AppProvider;
