import { isFunction, isNil, omit } from 'lodash-es';
import { OneServiceOptions } from 'types/services/services';

const buildServiceOptions = <Params>(options: OneServiceOptions<Params> | undefined, params: OptionalArg<Params>) => {
  if (isNil(options)) {
    return undefined;
  }

  const enabled = isFunction(options.enabled) ? options.enabled(...params) : options.enabled;

  return { ...omit(options, ['enabled']), enabled };
};

export default buildServiceOptions;
