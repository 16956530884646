import { QueryClient } from '@tanstack/react-query';
import PAGES from 'config/pages';
import signinLoader from 'config/route/loaders/signinLoader';
import Error400 from 'pages/error/Error400';
import Error401 from 'pages/error/Error401';
import Error403 from 'pages/error/Error403';
import Error404 from 'pages/error/Error404';
import Error500 from 'pages/error/Error500';
import ErrorJS from 'pages/error/ErrorJS';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const Signin = lazy(() => import('pages/signin/Signin'));
const Signup = lazy(() => import('pages/signup/Signup'));
const SigninWithSSO = lazy(() => import('pages/signin-sso/SigninWithSSO'));
const VerifyEmail = lazy(() => import('pages/verify-email/VerifyEmail'));

const ROUTES = (queryCLient: QueryClient): RouteObject[] => [
  {
    id: 'app',
    index: true,
    loader: signinLoader(queryCLient),
  },
  { id: 'signin', path: PAGES.authentication.signin, element: <Signin /> },
  { id: 'signinWithSSO', path: PAGES.authentication.signinWithSSO, element: <SigninWithSSO /> },
  { id: 'signup', path: PAGES.authentication.signup, element: <Signup /> },
  { id: 'verifyEmail', path: PAGES.authentication.verifyEmail, element: <VerifyEmail /> },
  { id: '400', path: PAGES.error.error400, element: <Error400 /> },
  { id: '401', path: PAGES.error.error401, element: <Error401 /> },
  { id: '403', path: PAGES.error.error403, element: <Error403 /> },
  { id: '500', path: PAGES.error.error500, element: <Error500 /> },
  { id: 'error', path: PAGES.error.errorJS, element: <ErrorJS /> },
  { id: '404', path: '*', element: <Error404 /> },
];

export default ROUTES;
