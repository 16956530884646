import { isNil } from 'lodash-es';
import { useEffect, useState } from 'react';
import { BrowserStorage, getValueFromStorage, removeFromStorage, setValueFromStorage } from 'utils/browser/browserStorage';

const buildDefault = <Data,>(storage: BrowserStorage, key: string, defaultData: Data) => {
  const stored = getValueFromStorage<Data>(storage, key);

  return stored ?? defaultData;
};

const useStorageState = <Data,>(storage: BrowserStorage, key: string, defaultData: Data): [typeof data, typeof setData] => {
  const [data, setData] = useState(() => buildDefault(storage, key, defaultData));

  useEffect(() => {
    if (isNil(data)) {
      // no data, remove
      removeFromStorage(storage, key);
    } else {
      // update value
      setValueFromStorage(storage, key, data);
    }
  }, [data, key, storage]);

  return [data, setData];
};

export const useLocalStorageState = <Data,>(key: string, defaultData: Data) => useStorageState('local', key, defaultData);

export const useSessionStorageState = <Data,>(key: string, defaultData: Data) => useStorageState('session', key, defaultData);
