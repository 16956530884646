import { SvgIcon } from '@mui/material';
import ErrorPage from 'components/layout/error-page/ErrorPage';
import useNavNavigate from 'hooks/navigation/useNavNavigate';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Image403 } from 'resources/svg/errors/403.svg';

const Error403 = () => {
  const { t } = useTranslation();
  const navigate = useNavNavigate();

  return (
    <ErrorPage
      title={t('error:403.title')}
      description={t('error:403.description')}
      img={<SvgIcon component={Image403} inheritViewBox />}
      button={{
        name: t('common:goBack'),
        action: () => navigate(-1),
      }}
    />
  );
};

export default Error403;
