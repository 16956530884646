import { isNil, toNumber } from 'lodash-es';
import { AccountDetailsServiceData } from 'services/account/accountServiceType';
import { AccountDetails, UnconfirmedEmail } from 'types/account/account';
import convertUnixSecondsToDate from 'utils/date/convertUnixSecondsToDate';

const convertUnconfirmedEmail = (data: AccountDetailsServiceData['unconfirmedEmail']): UnconfirmedEmail | undefined =>
  isNil(data)
    ? undefined
    : {
        ...data,
        expiresAt: data.expiresAt ? convertUnixSecondsToDate(data.expiresAt) : undefined,
      };

const convertAccountType = (type: number): AccountDetails['accountType'] => {
  switch (type) {
    case 1:
      return 'consumer';
    case 2:
      return 'business';
    default:
      throw new Error(`Unexpected account type : ${type}`);
  }
};

const convertLanguage = (language: number): AccountDetails['language'] => {
  switch (language) {
    case 0:
      return 'english';
    case 2:
      return 'dutch';
    default:
      throw new Error('Unexpected language');
  }
};

const convertAccountDetailsFromService = (data: AccountDetailsServiceData): AccountDetails => ({
  ...data,
  unconfirmedEmail: convertUnconfirmedEmail(data.unconfirmedEmail),
  accountType: convertAccountType(toNumber(data.accountType)),
  emailAddressValidated: data.emailAddressValidated === 1,
  language: convertLanguage(data.language),
  newsletter: data.newsletter === 1,
  agreeToc: isNil(data.agreeToc) ? undefined : data.agreeToc === 1,
  agreeAup: isNil(data.agreeAup) ? undefined : data.agreeAup === 1,
  agreeDpa: data.agreeAup === 1,
  isAllowedFlexMetal: data.isAllowedFlexMetal === 1,
});

export default convertAccountDetailsFromService;
