import { IDENTITY_API_URL, ONE_API_URL } from 'config/environment';
import OneUnauthorizedError from 'errors/common/OneUnauthorizedError';
import ky, { HTTPError, SearchParamsOption } from 'ky';
import { isNil, toNumber } from 'lodash-es';
import { isRestApiError } from 'types/services/rest-api';
import buildBadRequestError from 'utils/api/buildBadRequestError';

import isHTTPError from './isHTTPError';

type SearchParams = SearchParamsOption;

const extractJsonBody = async (error: HTTPError) => {
  try {
    const apiError = await error.response.json();

    return apiError;
  } catch (_) {
    return undefined;
  }
};

const buildHeaders = (token: string | undefined) => {
  if (!isNil(token)) {
    if (process.env.REACT_APP_ENV === 'dev') {
      return {
        Authorization: `Bearer ${token}`,
      };
    }
  }

  return {};
};

type GetOptions = { searchParams?: SearchParams; token?: string };
type PostOptions<Params> = { searchParams?: SearchParams; params?: Params; token?: string };
type PutOptions<Params> = { searchParams?: SearchParams; params?: Params; token?: string };
type PatchOptions<Params> = { searchParams?: SearchParams; params?: Params; token?: string };
type DeleteOptions<Params> = { searchParams?: SearchParams; params?: Params; token?: string };

const handleError = async (error: unknown) => {
  if (isHTTPError(error)) {
    if (toNumber(error.response.status) === 403) {
      throw new OneUnauthorizedError(error);
    }

    const apiError = await extractJsonBody(error);

    if (isRestApiError(apiError)) {
      if (apiError.status === 400) {
        // invalid field
        const badRequestError = buildBadRequestError(apiError);

        if (!isNil(badRequestError)) {
          throw badRequestError;
        }
      }
    }
  }
};

class ApiFetcher {
  api: typeof ky;

  constructor(baserUrl = '') {
    this.api = ky.create({ prefixUrl: baserUrl, credentials: 'include' });
  }

  async get<Data>(relativeUrl: string, opts?: GetOptions) {
    try {
      const headers = buildHeaders(opts?.token);
      const response = await this.api.get(relativeUrl, { searchParams: opts?.searchParams, headers });
      const data = await response.json<Data>();

      return { data, response };
    } catch (error) {
      await handleError(error);

      throw error;
    }
  }

  async post<Data, Params>(relativeUrl: string, opts?: PostOptions<Params>) {
    try {
      const headers = buildHeaders(opts?.token);
      const response = await this.api.post(relativeUrl, { json: opts?.params, searchParams: opts?.searchParams, headers });

      const data = await response.json<Data>();

      return { data, response };
    } catch (error) {
      await handleError(error);

      throw error;
    }
  }

  async put<Data, Params>(relativeUrl: string, opts?: PutOptions<Params>) {
    try {
      const headers = buildHeaders(opts?.token);
      const response = await this.api.put(relativeUrl, { json: opts?.params, searchParams: opts?.searchParams, headers });
      const data = await response.json<Data>();

      return { data, response };
    } catch (error) {
      await handleError(error);

      throw error;
    }
  }

  async patch<Data, Params>(relativeUrl: string, opts?: PatchOptions<Params>) {
    try {
      const headers = buildHeaders(opts?.token);
      const response = await this.api.patch(relativeUrl, { json: opts?.params, searchParams: opts?.searchParams, headers });
      const data = await response.json<Data>();

      return { data, response };
    } catch (error) {
      await handleError(error);

      throw error;
    }
  }

  async delete<Data, Params>(relativeUrl: string, opts?: DeleteOptions<Params>) {
    try {
      const headers = buildHeaders(opts?.token);
      const response = await this.api.delete(relativeUrl, { json: opts?.params, searchParams: opts?.searchParams, headers });
      const data = await response.json<Data>();

      return { data, response };
    } catch (error) {
      await handleError(error);

      throw error;
    }
  }
}

export const identityApi = new ApiFetcher(IDENTITY_API_URL);

export const oneApi = new ApiFetcher(ONE_API_URL);
